import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FlexLayoutType } from './@components/flex-layout.type';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedModule } from './@components/shared.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerTranslateExtension } from './@components/translation.extension';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';


// const config: SocketIoConfig = {
//   url: environment.root, // Your Socket.IO server URL
//   options: {
//     query: {
//       token: localStorage.getItem('token') // Pass the token as a query parameter
//     }
//   }
// };

registerLocaleData(en);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FlexLayoutType
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    // SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
      }
    ),
    FormlyModule.forRoot(
      {
        types: [
          { name: 'flex-layout', component: FlexLayoutType }
        ],
      }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService] },],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private title: Title, private translator: TranslateService) {
    translator.get('branding.page.title').subscribe(x => {
      title.setTitle(x);
    })
  }
 }
