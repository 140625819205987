import { Injectable, NgZone } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class SocketService extends Socket {
  constructor(private router: Router, private message: NzMessageService,private ngZone: NgZone) {
    super({ url: `${environment.root}cms`, options: { query: {token: localStorage.getItem('token')} } });
    this.on('error', (error) => {
      // console.log(error);
      if(error.type == 'UnauthorizedError') {
        localStorage.removeItem('token');
        this.router.navigate(['./login']);
        location.reload();
      }
    })

    this.on('disconnect', (reason) => {
      this.handleDisconnection(reason);
    });
  }


  public on(event: string, callback: Function): void {
    super.on(event, (data: any) => {
      this.ngZone.run(() => {
        callback(data);  // Ensure Angular's change detection is triggered
      });
    });
  }

  private handleDisconnection(reason: string): void {
    this.message.error('WebSocket connection has been disconnected. Reason: ' + reason);
    console.log('WebSocket connection has been disconnected. Reason: ' + reason)
  }

  
 
}






















// import { Injectable } from '@angular/core';
// import * as io from 'socket.io-client';
// import { environment } from 'src/environments/environment';
// import { Router } from '@angular/router';
// import { NzMessageService } from 'ng-zorro-antd/message';

// @Injectable({
//   providedIn: 'root'
// })
// export class SocketService {
//   private socket: io.Socket;

//   constructor(private router: Router, private message: NzMessageService) {
//     this.socket = io(`${environment.root}cms`, {
//       query: { token: localStorage.getItem('token') },
//     });

//     this.socket.on('error', (error) => {
//       // Handle errors
//       if (error.type === 'UnauthorizedError') {
//         localStorage.removeItem('token');
//         this.router.navigate(['./login']);
//         location.reload();
//       }
//     });

//     this.socket.on('disconnect', (reason) => {
//       this.handleDisconnection(reason);
//     });
//   }

//   private handleDisconnection(reason: string): void {
//     this.message.error('WebSocket connection has been disconnected. Reason: ' + reason);
//     console.log('WebSocket connection has been disconnected. Reason: ' + reason);
//   }

//   // Define other methods to interact with the socket as needed
//   public on(event: string, callback: (...args: any[]) => void): void {
//     this.socket.on(event, callback);
//   }

//   public emit(event: string, data: any): void {
//     this.socket.emit(event, data);
//   }
// }
