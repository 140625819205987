import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ar_EG,
  de_DE,
  en_US,
  es_ES,
  fr_FR,
  hy_AM,
  ja_JP,
  ko_KR,
  NzI18nService,
  pt_PT,
  ru_RU,
  zh_CN,
} from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isDarkMode = false;
  isCollapsed = false;
  
  constructor(translate: TranslateService, private i18n: NzI18nService) {
    translate.setDefaultLang('en');
    if(localStorage.getItem('lang') != null) {
      translate.use(localStorage.getItem('lang'));
      switch(localStorage.getItem('lang')) {
        case('en'):
        this.i18n.setLocale(en_US);
        break;
        case('es'):
        this.i18n.setLocale(es_ES);
        registerLocaleData(es);
        break;
        case('fr'):
        this.i18n.setLocale(fr_FR);
        break;
        case('de'):
        this.i18n.setLocale(de_DE);
        break;
        case('ar'):
        this.i18n.setLocale(ar_EG);
        break;
        case('hy'):
        this.i18n.setLocale(hy_AM);
        break;
        case('ko'):
        this.i18n.setLocale(ko_KR);
        break;
        case('ru'):
        this.i18n.setLocale(ru_RU);
        break;
        case('zh'):
        this.i18n.setLocale(zh_CN);
        break;
        case('ja'):
        this.i18n.setLocale(ja_JP);
        break;
        case('pt'):
        this.i18n.setLocale(pt_PT);
        break;
      }
    }
  }

  ngOnInit(): void {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      //this.changeTheme();
    }
  }

  changeTheme(): void {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) {
      let head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      style.type = 'text/css';
      style.rel = 'stylesheet';
      style.id = 'dark-theme';
      style.href = '/theme.dark.css';
      head.appendChild(style);
    } else {
      const dom = document.getElementById('dark-theme');
      if (dom) {
        dom.remove();
      }
    }
  }
}
